import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

// When using WAF, handle waf-protected calls with
if (process.env.REACT_APP_AWS_WAF_INTEGRATION_USE === 'true') {
    // Define protected routes and their methods
    const protectedRoutes = {
        '/sms': ['POST', 'PATCH'],
    };

    // Utility function to convert AWS WAF fetch response to Axios response format
    const convertFetchResponseToAxios = async (fetchResponse, originalConfig) => {
        const contentType = fetchResponse.headers.get('Content-Type') || '';

        let data;

        // Determine the response type based on Content-Type header
        if (contentType.includes('application/json')) {
            try {
                data = await fetchResponse.json();
            } catch (err) {
                console.error('Error parsing JSON response:', err);
                data = null;
            }
        } else if (contentType.includes('text/')) {
            data = await fetchResponse.text();
        } else if (contentType.includes('application/octet-stream') || contentType.includes('image/')) {
            // Handle binary data like files or images
            data = await fetchResponse.blob();
        } else if (contentType.includes('application/x-www-form-urlencoded') || contentType.includes('multipart/form-data')) {
            // Handle form data as text for simplicity (you can modify if needed)
            data = await fetchResponse.text();  // Typically, form data is sent as text
        } else {
            // Default to ArrayBuffer for unknown types (raw binary data)
            data = await fetchResponse.arrayBuffer();
        }

        return {
            data,
            status: fetchResponse.status,
            statusText: fetchResponse.statusText,
            headers: fetchResponse.headers,  // Fetch does not expose all headers easily; customize if needed
            config: originalConfig,   // The original request config
            response: fetchResponse,
            request: {}
        };
    };


    // Request interceptor to handle protected routes
    instance.interceptors.request.use(async (config) => {
        const routePath = config.url.replace(config.baseURL, ''); // Extract route path
        const method = config.method.toUpperCase();

        if (protectedRoutes[routePath] && protectedRoutes[routePath].includes(method)) {
            try {
                const token = await window.AwsWafIntegration.getToken();

                config.headers['x-aws-waf-token'] = token;
            } catch (error) {
                console.error('Failed to get AWS WAF token or fetch request failed', error);
                throw error;
            }
        }

        return config;
    }, (error) => {
        return Promise.reject(error);
    });
}

export default instance;
